import React from 'react';
import { connect } from 'react-redux';
import { Select } from '../../components/Select';
import { Button } from '../../components/Button';
import { ReportsResource } from '../../resources';
import { showErrorMessage, closeModal } from '../../helpers/modal';
import { getFormattedDate, getPastDays } from '../../components/Calendar';
import { Textbox } from '../../components/Textbox';
import { Grid, Grid1 } from '../../components/Grids';

export class _ReportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom:        getFormattedDate(getPastDays(7)),
      dateTo:          getFormattedDate(getPastDays(-1)),
      reportType:      '',
      transactionType: 'all',
      busy:            false,
      error:           ''
    };
  }

  change = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  isDateValid = (date) => !Number.isNaN(Date.parse(date));

  generate = () => {
    const { dateFrom, dateTo, reportType, transactionType } = this.state;

    this.setState({ busy: true });
    closeModal();

    ReportsResource.create(
      {
        start_date:  new Date(dateFrom).toLocaleDateString('en-CA'),
        end_date:    new Date(dateTo).toLocaleDateString('en-CA'),
        report_type: reportType,
        params:      { kind: transactionType },
      },
      'Generating Report...',
      {
        onSuccess: () => {
          this.setState({ busy: false });
          closeModal();
          ReportsResource.list();
        },
        onError: (message) => {
          this.setState({ busy: false, error: message });
          showErrorMessage(message);
        },
      }
    );
  };

  render() {
    const { dateFrom, dateTo, reportType, transactionType, busy, error } = this.state;
    const { options }                                                    = this.props.reports;
    const buttonText                                                     = busy ? 'Generating...' : 'Generate Report';
    const datesValid                                                     = this.isDateValid(dateFrom) && this.isDateValid(dateTo);

    return (
      <form autoComplete="off" className="v-modal-form v-width-40 v-center-column v-center-left">
        <Select
          name="reportType"
          label="Report Type"
          onChange={this.change}
          value={reportType}
          items={options.report_types.slice(1).map(([label, value]) => ({ label, value }))}
        />

        <Grid>
          <Grid1 className="v-padding-right-3">
            <Textbox
              label="From"
              name="dateFrom"
              value={dateFrom}
              onChange={this.change}
            />
          </Grid1>
          <Grid1>
            <Textbox
              label="To"
              name="dateTo"
              value={dateTo}
              onChange={this.change}
            />
          </Grid1>
        </Grid>

        {reportType === 'dashboard_balances' && (
          <Select
            label="Transaction Type"
            items={options['params.kinds'].map(([label, value]) => ({ label, value }))}
            onChange={this.change}
            value={transactionType}
            name="transactionType"
          />
        )}

        <div className="v-error-message">{error}</div>
        <br />

        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Cancel" white onClick={closeModal} />
          <Button disabled={busy || !reportType || !this.isDateValid(dateFrom) || !this.isDateValid(dateTo)} label={busy ? 'Generating...' : 'Generate Report'} onClick={this.generate} />
        </div>
      </form>
    );
  }
}

export const ReportModal = connect(({ reports }) => ({ reports }))(_ReportModal); 