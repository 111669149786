import React from 'react';
import { connect } from 'react-redux';
import { PlaidLink } from 'react-plaid-link';
import { Button } from '../../../components/Button';
import { PaymentMethodResource } from '../../../resources';
import { IconBank, IconAddNew } from '../../../helpers/icons';

import { showModal } from '../../../helpers/modal';
import { BankAccountModal } from '../BankAccountModal';

const addBankAccount = (type, external) => {
  showModal({
    title:     'Add Bank Account',
    component: BankAccountModal,
    payload:   { type, external },
  });
};

class PlaidCheckoutFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isBusy: true };

    PaymentMethodResource.linkToken().then((response) => {
      this.setState({ linkToken: response.link_token, isBusy: false });
    });
  }

  setBusy() {
    this.setState({ isBusy: !this.state.isBusy });
  }

  render() {
    const { isBusy, linkToken } = this.state;

    const { publicKey, env, bankAccountType, external } = this.props;
    const onSubmit                                      = async (plaid_token, metadata) => {
      this.setBusy();
      const body = {
        payment_method: {
          type:     bankAccountType || 'BankAccount',
          name:     metadata.institution.name,
          plaid_token,
          plaid_id: metadata.account_id,
          external
        },
      };
      PaymentMethodResource.create(body, 'Adding bank account...').then(this.setBusy.bind(this));
    };

    if (!publicKey) return <div>Missing Plaid keys!</div>;
    if (isBusy) return <div>Loading...</div>;

    return (
      <div className="v-padding-2 v-center v-grids v-grid-column">
        {isBusy ? (
          <Button disabled round white label="ADD NEW ACCOUNT" icon={IconBank} onClick={null} />
        ) : (
          <PlaidLink
            className="v-plaid-button"
            clientName="Vanlo"
            env={env}
            product={['auth']}
            selectAccount
            token={linkToken}
            onExit={() => null}
            onSuccess={(token, metadata) => onSubmit(token, metadata)}
          >
            <Button round white label="ADD WITH PLAID" icon={IconAddNew} propagate />
            <Button
              round
              white
              label="ADD BY ACCOUNT NUMBER"
              onClick={() => addBankAccount(bankAccountType || 'BankAccount', external)}
              icon={IconAddNew}
            />
          </PlaidLink>
        )}
      </div>
    );
  }
}

export const PlaidCheckoutForm = connect(({ user }) => ({
  publicKey: user.keys && user.keys.plaid.pk,
  env:       user.keys && user.keys.plaid.env,
}))(PlaidCheckoutFormComponent);
