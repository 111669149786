import React from 'react';
import { connect } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';
import { max, map, find, cloneDeep, isEqual } from 'lodash';

import { Title, Button, Select, Textbox, Grid, Grid1 } from '../../components';
import { SearchPresetsResource } from '../../resources';
import { useStateManagement } from '../../helpers/StateManagement';

import PresetManager from './elements';

class SearchPresets extends React.Component {
  propsWillUpdate = ['searchPresets']

  resource = SearchPresetsResource

  constructor(props) {
    super(props);
    useStateManagement(this);
  }

  freshState = () => ({
    presets: this.props.searchPresets.presets || [],
    filters: this.props.searchPresets.filters || []
  });

  addCondition = (id) => {
    this.updatePresetState(id, (x) => x.conditions.push({ key: this.getNewKey(x.conditions) }));
  };

  deleteCondition = (id, key) => {
    this.updatePresetState(id, (x) => delete x.conditions[key]);
  };

  updateCondition = (id, key, param, value) => {
    const { filters } = this.state;
    this.updateStateElement(
      ['presets', (x) => x.id === id, 'conditions', key],
      (cond) => {
        if (param === 'attribute' && cond.attribute && filters[value].type !== filters[cond.attribute].type) {
          delete cond.operator;
          delete cond.reference;
        }
        cond[param] = value;
      }
    );
  };

  addPreset = () => {
    this.addStateElement('presets', { id: 'new', name: '', conditions: [], editor: true });
  };

  editPreset = (id) => {
    const editor = find(this.state.presets, 'editor');
    if (editor) editor.editor = false; // only 1 editor at a time
    this.updatePresetState(id, (x) => x.editor = true);
  };

  updatePresetState = (id, mutation) => {
    this.updateStateElement(['presets', (x) => x.id === id], mutation);
  };

  savePreset = () => {
    this.saveRecord(find(this.state.presets, 'editor'));
  };

  reorderPresets = ({ active, over }) => {
    if (!active || !over || active.id === over.id) return;
    const { presets } = this.state;
    const ids         = map(presets, 'id');
    const newOrder    = arrayMove(ids, ids.indexOf(active.id), ids.indexOf(over.id));
    this.resource.update({ id: 'reorder', ...newOrder }, false);
    // for instant rerender
    this.setState({ presets: arrayMove(presets, ids.indexOf(active.id), ids.indexOf(over.id)) });
  };

  getNewKey = (units) => {
    const maxKey = max(map(units, 'key'));
    return maxKey === undefined ? 0 : maxKey + 1;
  };

  render() {
    const { presets, filters } = this.state;

    return (
      <div className="v-page">
        <Title title="Search Presets" subtitle="Combine filters to use for search on Shipments and Shop Orders pages">
          <Button label="Add Preset" className="v-title-button" onClick={this.addPreset} disabled={find(presets, 'editor')} />
        </Title>

        {presets.length === 0 && (
          <div className="v-empty-state-container">
            <div className="v-empty-state">
              <div className="v-empty-state-icon">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="10" y="10" width="50" height="60" rx="4" fill="#E0E0E0" />
                  <rect x="20" y="25" width="30" height="5" rx="2" fill="#BDBDBD" />
                  <rect x="20" y="35" width="30" height="5" rx="2" fill="#BDBDBD" />
                  <rect x="20" y="45" width="30" height="5" rx="2" fill="#BDBDBD" />
                  <circle cx="60" cy="20" r="15" fill="#F5F5F5" stroke="#BDBDBD" strokeWidth="2" />
                  <line x1="50" y1="30" x2="70" y2="10" stroke="#BDBDBD" strokeWidth="2" />
                </svg>
              </div>
              <h2 className="v-empty-state-title">You&apos;ve created no presets</h2>
              <p className="v-empty-state-message">Create your first preset to speed up shipment search</p>
              <p><Button label="Add Preset" className="v-title-button" onClick={this.addPreset} /></p>
            </div>
          </div>
        )}
        <PresetManager presets={presets} page={this} />
      </div>
    );
  }
}

export default connect(({ searchPresets }) => ({ searchPresets }))(SearchPresets);
