import React from 'react';
import { classList } from '../../helpers/classList';
import { Button } from '../../components/Button';
import { Textbox } from '../../components/Textbox';
import { Hr } from '../../components/Hr';
import { closeModal } from '../../helpers/modal';

export class VerifyModal extends React.Component {
  state = { amounts: [0, 0] };

  changeAmount(index, { target: { value } }) {
    const { amounts } = this.state;
    amounts[index]    = value;
    this.setState({ amounts });
  }

  render() {
    const { performVerify } = this.props;
    const { amounts }       = this.state;
    return (
      <div className={classList('v-modal-form v-width-40 v-center-column v-center-left')}>
        <Textbox label="Amount #1" value={amounts[0]} onChange={this.changeAmount.bind(this, 0)} />
        <Textbox label="Amount #2" value={amounts[1]} onChange={this.changeAmount.bind(this, 1)} />
        <Hr />
        <br />
        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button disabled={amounts.some((a) => a <= 0)} label="Verify" onClick={() => performVerify(amounts)} />
        </div>
      </div>
    );
  }
}
