import React from 'react';
import { classList } from '../../helpers/classList';
import { Button } from '../../components/Button';
import { Hr } from '../../components/Hr';
import { closeModal } from '../../helpers/modal';
import { PersonsResource } from '../../resources';

export default class DeleteUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  deleteUser = () => {
    this.setState({ busy: true });

    const {
      user: { id },
    } = this.props;

    PersonsResource.delete(id).then(() => closeModal());
  };

  render() {
    const { user }        = this.props;
    const { busy, error } = this.state;

    return (
      <div className={classList('v-modal-form v-width-50 v-center-column v-center-left')}>
        <label>
          Delete user?
          <div className="v-remove-warehouse-address">{user.name}</div>
        </label>
        <Hr />
        <div className="v-error-message">{error}</div>
        <br />
        <div className="v-center-right">
          <Button disabled={busy} label={busy ? 'Processing...' : 'Delete User'} onClick={this.deleteUser} />
        </div>
      </div>
    );
  }
}
