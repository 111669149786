import { resource } from './resource';
import { queryVanlo } from './query';

function reorder(ids) {
  this.runAndReload(queryVanlo(`/dashboard/automations/reorder`, 'PATCH', { ids }), false);
}

export const AutomationsResource = resource({
  route:      '/automations?v=1',
  storageKey: 'SET_AUTOMATIONS',
  reorder,
});
