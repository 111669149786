import React from 'react';
import { connect } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';
import { max, map, find, cloneDeep, isEqual } from 'lodash';

import { Title, Button, Select, Textbox, Grid, Grid1 } from '../../components';
import { AutomationsResource } from '../../resources';

import RuleManager from './elements';

class Automations extends React.Component {
  state = { rules: cloneDeep(this.props.automations.rules) };

  componentDidUpdate(prevProps, prevState) {
    const reducer = this.props.automations;
    if (reducer?.rules && !isEqual(prevProps?.automations, reducer)) {
      // eslint-disable-next-line
      this.resetState();
    }
  }

  resetState = () => {
    this.setState({ rules: cloneDeep(this.props.automations.rules) });
  };

  addLogic = (id, logicGroup) => {
    this.updateRuleState(id, (rule) => rule[logicGroup].push({ key: this.getNewKey(rule[logicGroup]) }));
  };

  deleteLogic = (id, logicGroup, key) => {
    this.updateRuleState(id, (rule) => delete rule[logicGroup][key]);
  };

  updateLogic = (id, path, value) => {
    this.updateRuleState(id, (rule) => {
      const param = path.pop();
      const unit  = path.reduce((obj, key) => obj[key], rule);
      // const dict = this.props.automations.conditions;
      if (path[0] === 'conditions' && param === 'attribute') {
        delete unit.operator;
        delete unit.reference;
      }
      if (path[0] === 'actions' && param === 'type') {
        delete unit.target;
        delete unit.value;
        delete unit.services;
      }
      unit[param] = value;
      unit.key    = unit.key || this.getNewKey(rule[path[0]]);
    });
  };

  reorderLogic = (id, logicGroup, { active, over }) => {
    if (!active || !over || active.id === over.id) return;
    this.updateRuleState(id, (rule) => {
      const unitKeys   = map(rule[logicGroup], 'key');
      const positions  = map([active, over], (x) => unitKeys.indexOf(x.id.split(':')[1]));
      rule[logicGroup] = arrayMove(rule[logicGroup], ...positions);
    });
  };

  addRule = () => {
    this.state.rules.push({ id: 'new', name: '', conditions: [], actions: [], editor: true });
    this.setState({ rules: this.state.rules });
  };

  // only 1 editor at a time
  editRule = (id) => {
    const editor = find(this.state.rules, 'editor');
    editor && this.updateRuleProps({ id: editor.id, editor: false });
    this.updateRuleProps({ id, editor: true });
  };

  updateRule = (props) => {
    this.updateRuleProps(props);
    AutomationsResource.update({ id: props.id, automation: props });
  };

  saveRule = (id) => {
    const { name, conditions, actions } = find(this.state.rules, 'editor');
    id === 'new'
      ? AutomationsResource.create({ automation: { name, conditions, actions, priority: this.state.rules.length } })
      : AutomationsResource.update({ id, automation: { name, conditions, actions } });
  };

  reorderRules = ({ active, over }) => {
    if (!active || !over || active.id === over.id) return;
    const { rules } = this.state;
    const ids       = map(rules, 'id');
    const newOrder  = arrayMove(ids, ids.indexOf(active.id), ids.indexOf(over.id));
    this.setState({ rules: arrayMove(rules, ids.indexOf(active.id), ids.indexOf(over.id)) });
    AutomationsResource.reorder(newOrder);
  };

  updateRuleState = (id, mutation) => {
    const rule = this.state.rules.find((x) => x.id === id);
    mutation(rule);
    this.setState({ rules: this.state.rules });
  };

  updateRuleProps = (props) => {
    this.updateRuleState(props.id, (rule) => Object.assign(rule, props));
  };

  getNewKey = (units) => {
    const maxKey = max(map(units, 'key'));
    return maxKey === undefined ? 0 : maxKey + 1;
  };

  render() {
    const {
      props: {
        automations: { conditions, actions },
      },
      state: { rules },
    } = this;

    return (
      <div className="v-page">
        <Title title="Automations" subtitle="Automate your workflow for Shipments imported from CSV and eCommerce">
          <Button label="Add Rule" className="v-title-button" onClick={this.addRule} disabled={find(rules, 'editor')} />
        </Title>

        {rules.length === 0 && (
          <div className="v-empty-state-container">
            <div className="v-empty-state">
              <div className="v-empty-state-icon">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="10" y="10" width="50" height="60" rx="4" fill="#E0E0E0" />
                  <rect x="20" y="25" width="30" height="5" rx="2" fill="#BDBDBD" />
                  <rect x="20" y="35" width="30" height="5" rx="2" fill="#BDBDBD" />
                  <rect x="20" y="45" width="30" height="5" rx="2" fill="#BDBDBD" />
                  <circle cx="60" cy="20" r="15" fill="#F5F5F5" stroke="#BDBDBD" strokeWidth="2" />
                  <line x1="50" y1="30" x2="70" y2="10" stroke="#BDBDBD" strokeWidth="2" />
                </svg>
              </div>
              <h2 className="v-empty-state-title">You&apos;ve created no rules</h2>
              <p className="v-empty-state-message">Create your first automation rule to generate labels automatically based on CSV uploads or eCommerce integrations</p>
              <p><Button label="Add Rule" className="v-title-button" onClick={this.addRule} /></p>
            </div>
          </div>
        )}
        <RuleManager rules={rules} page={this} />
      </div>
    );
  }
}

export default connect(({ automations }) => ({ automations }))(Automations);
