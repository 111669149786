import React from 'react';
import { PasswordBox, Button } from '../../components';
import { PasswordResource } from '../../resources';
import { showTextMessage, closeModal } from '../../helpers/modal';

class ResetPasswordModal extends React.Component {
  state = { values: {}, busy: false, error: '' };

  validateForm = (values) => {
    const { current_password, password, password_confirmation } = values;
    if ((current_password || '').length < 8) {
      return 'Current password should be at least 8 characters';
    }
    if ((password || '').length < 8) {
      return 'New password should be at least 8 characters';
    }
    if (!(password && password_confirmation) || password !== password_confirmation) {
      return 'Password and repeat password should match';
    }
    return null;
  };

  submit = () => {
    const error = this.validateForm(this.state.values);
    if (error) return this.setState({ error });
    this.setState({ busy: true });
    PasswordResource.update({ user: this.state.values }).then((r) => {
      showTextMessage(r.message);
      this.setState({ busy: false });
      closeModal();
    });
  };

  change = ({ target: { name, value } }) => {
    this.setState({ values: { ...this.state.values, [name]: value } });
  };

  render() {
    const { busy, values, error } = this.state;
    const change                  = this.change.bind(this);

    return (
      <form autoComplete="off" className="v-modal-form v-width-40 v-center-column v-center-left">
        <PasswordBox
          value={values.current_password}
          onChange={change}
          disabled={busy}
          name="current_password"
          type="password"
          label="Current password"
        />
        <PasswordBox
          value={values.password}
          onChange={change}
          disabled={busy}
          name="password"
          type="password"
          label="New password"
        />
        <PasswordBox
          value={values.password_confirmation}
          onChange={change}
          disabled={busy}
          name="password_confirmation"
          type="password"
          label="Repeat new password"
        />

        {error && <div className="v-error-message">{error}</div>}
        <br />

        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Cancel" white onClick={closeModal} />
          <Button disabled={busy} label="Save changes" onClick={this.submit} />
        </div>
      </form>
    );
  }
}

export default ResetPasswordModal; 