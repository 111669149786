import React from 'react';
import { classList } from '../../helpers/classList';
import { Textbox } from '../../components/Textbox';
import { Button } from '../../components/Button';
import { Grid, Grid1 } from '../../components/Grids';
import { closeModal } from '../../helpers/modal';
import { WarehouseAddressesResource } from '../../resources';

export class EditWarehouseContainer extends React.Component {
  state = { ...this.props.warehouse };

  change({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  submit() {
    this.setState({ busy: true });
    const { phone, zip, state, city, street1, name, id } = this.state;
    const address                                        = { phone, zip, state, city, street1, name };
    const promise                                        = id
      ? WarehouseAddressesResource.update({ id, address })
      : WarehouseAddressesResource.create({ address });
    promise.then((r) => {
      this.setState({ busy: false });
      closeModal();
    });
  }

  render() {
    const { busy, error, phone, zip, state, city, street1, name, id } = this.state;
    const change                                                      = this.change.bind(this);
    const isValid                                                     = [phone, zip, state, city, street1].every((f) => f);
    const buttonText                                                  = busy ? 'Submitting data...' : `${id ? 'Update' : 'Add'} warehouse`;
    return (
      <form
        autoComplete="off"
        className={classList('v-modal-form v-width-40 v-center-column v-center-left')}
      >
        <Textbox name="name" label="Nickname" disabled={busy} onChange={change} value={name} />
        <Textbox name="street1" label="Street name" disabled={busy} onChange={change} value={street1} />
        <Textbox name="city" label="City" disabled={busy} onChange={change} value={city} />
        <Grid>
          <Grid1 className="v-padding-right-3">
            <Textbox name="state" label="State" disabled={busy} onChange={change} value={state} />
          </Grid1>
          <Grid1>
            <Textbox
              name="zip"
              label="Zip"
              disabled={busy}
              onChange={change}
              value={zip}
              required="required"
              // constraints={{
              //   pattern:    '^[0-9]{5}(-[0-9]{4})?$',
              //   validChars: '^[\\d-]*$',
              //   maxLength:  10,
              // }}
              size="10"
            />
          </Grid1>
        </Grid>
        <Textbox name="phone" label="Phone" disabled={busy} onChange={change} value={phone} />
        <div className="v-error-message">{error}</div>
        <br />
        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button disabled={busy || !isValid} label={buttonText} onClick={this.submit.bind(this)} />
        </div>
      </form>
    );
  }
}
