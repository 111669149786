import { resource } from './resource';
import { readStorageData } from '../helpers/localStorage';

const route      = '/addresses';
const storageKey = 'SET_WAREHOUSE_ADDRESSES';
const filters    = {
  per:  readStorageData('per') || 50,
  page: 1,
};

export const WarehouseAddressesResource = resource({ route, storageKey, filters });

