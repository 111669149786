import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { Hr } from './Hr';
import { Button } from './Button';
import { closeModal, showModal } from '../helpers/modal';
import { DateSelect } from './DateSelect';

export const getPastDays = (days) => {
  const d = new Date();
  d.setHours(d.getHours() - days * 24);
  return d;
};

const getPastMonths = (months) => {
  const d = new Date();
  d.setMonth(d.getMonth() - months);
  return d;
};

const CalendarComponent = ({ initialDates, onDateSelected }) => {
  const [date, setDate] = useState(initialDates || [new Date(), new Date()]);

  const selectDay     = (days) => () => {
    closeModal();
    onDateSelected([getPastDays(days), new Date()]);
  };
  const previousMonth = () => {
    closeModal();
    const startDate = getPastMonths(1);
    startDate.setDate(1);
    const endDate = new Date();
    endDate.setDate(1);
    endDate.setHours(endDate.getHours() - 24);
    onDateSelected([startDate, endDate]);
  };

  const onManualChange = (index, newDate) => {
    date[index] = newDate;
    setDate([...date]);
  };

  return (
    <div>
      <div className="v-calendar-container">
        <div className="v-calendar-table">
          <Calendar onChange={setDate} value={date} selectRange minDate={getPastMonths(120)} maxDate={getPastDays(-1)} />
        </div>
        <div className="v-calendar-dates">
          <span className="v-range-date" onClick={selectDay(0)}>
            Today
          </span>
          <span className="v-range-date" onClick={selectDay(1)}>
            Yesterday
          </span>
          <span className="v-range-date" onClick={selectDay(6)}>
            Last 7 days
          </span>
          {/* <span className='v-range-date' onClick={selectDay(13)}>2 Weeks ago</span> */}
          {/* <span className='v-range-date' onClick={selectDay(20)}>3 Weeks ago</span> */}
          <span className="v-range-date" onClick={selectDay(30)}>
            Last 30 days
          </span>
          <span className="v-range-date" onClick={previousMonth}>
            Previous Month
          </span>
          {/* <span className='v-range-date' onClick={selectMonth(3)}>Last 3 Month</span> */}
        </div>
      </div>
      <Hr className="v-hr-2" />
      <div className="v-calendar-footer">
        <div className="v-calendar-ranges">
          <DateSelect date={date[0]} onFinish={onManualChange.bind(null, 0)} />
          -
          <DateSelect date={date[1]} onFinish={onManualChange.bind(null, 1)} />
        </div>

        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button
            label="Select"
            onClick={() => {
              closeModal();
              onDateSelected(date);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const getFormattedDate = (date) => date.toLocaleDateString('en-US');

export const showCalendarModal = (initialDates, onDateSelected) => () => {
  showModal({
    title:     'Select date',
    component: CalendarComponent,
    payload:   { onDateSelected, initialDates },
  });
};

export function createDate(date) {
  const [m, d, y] = date.split('/');
  return new Date(`${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}T00:00:00Z`);
}
