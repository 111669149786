import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Title } from '../../components/Title';
import { ReportsResource } from '../../resources';
import { TableFiltersComponent } from '../../components/TableFiltersComponent';
import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { showModal } from '../../helpers/modal';
import { ReportModal } from './ReportModal';
import tableConfig from './tableConfig';

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...ReportsResource.filters };
  }

  setPage = (page) => {
    if (page !== this.state.page) {
      this.setState({ page });
      ReportsResource.setFilter('page', page);
      ReportsResource.list();
    }
  };

  createReport = () => {
    showModal({
      title:     'Generate Report',
      component: ReportModal
    });
  };

  render() {
    const { reports, options, total_count } = this.props.reports;
    const { page, per }                     = this.state;

    return (
      <div className="v-page">
        <Title title="Reports">
          <div className="v-title-button">
            <Button onClick={this.createReport}>Generate Report</Button>
          </div>
        </Title>

        <TableFiltersComponent
          display="filters dates"
          resource={ReportsResource}
          filters={[{ name: 'report_type', label: 'Type', options: options.report_types }]}
          onChange={(p) => this.setState({ ...p })}
        />

        <Table
          noScroll
          data={reports}
          context={this}
          config={tableConfig.call(this)}
          tableClass="v-table-invoices"
          trClass={this.trClass}
        />

        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage} />
      </div>
    );
  }
}

export default withRouter(connect(({ reports }) => ({ reports }))(Reports));
