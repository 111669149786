import React from 'react';
import { classList } from '../../helpers/classList';
import { Button } from '../../components/Button';
import { Hr } from '../../components/Hr';
import { closeModal } from '../../helpers/modal';

export const RemoveModal = ({ title, message, performDelete }) => (
  <div className={classList('v-modal-form v-width-40 v-center-column v-center-left')}>
    <label>
      {title}
      <div className="v-remove-warehouse-address">{message}</div>
    </label>
    <Hr />
    <br />
    <div className="v-center-right">
      <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
      <Button label="Remove" onClick={performDelete} />
    </div>
  </div>
);
