import React from 'react';
import { classList } from '../../helpers/classList';
import { closeModal } from '../../helpers/modal';
import { Textbox } from '../../components/Textbox';
import { Button } from '../../components/Button';
import { PersonsResource } from '../../resources';

class AddUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  submit = () => {
    this.setState({ busy: true });

    const { name, email, password } = this.state;
    const user                      = { name, email, password };

    PersonsResource.create({ person: user }, null, {
      onError: (message) => {
        this.setState({ error: message });
      },
    }).then((response) => {
      this.setState({ busy: false });

      if (response.success) {
        closeModal();
      }
    });
  };

  render() {
    const { busy, error, name, email, password } = this.state;
    const buttonText                             = busy ? 'Adding User...' : 'Add User';
    const isValid                                = [name, email, password].every((f) => f);

    return (
      <form
        autoComplete="off"
        className={classList('v-modal-form v-width-40 v-center-column v-center-left')}
      >
        <Textbox name="name" label="Name" disabled={busy} onChange={this.handleChange} value={name} />

        <Textbox name="email" label="Email" disabled={busy} onChange={this.handleChange} value={email} />

        <Textbox
          name="password"
          label="Password"
          type="password"
          disabled={busy}
          onChange={this.handleChange}
          value={password}
        />

        <div className="v-error-message">{error}</div>
        <br />
        <div className="v-center-right">
          <Button disabled={busy || !isValid} label={buttonText} onClick={this.submit} />
        </div>
      </form>
    );
  }
}

export default AddUserModal;
