import React from 'react';
import { classList } from '../../helpers/classList';
import { Textbox } from '../../components/Textbox';
import { Button } from '../../components/Button';
import { closeModal } from '../../helpers/modal';
import { ApiKeyResource } from '../../resources';

export class ApiKeyModal extends React.Component {
  state = { description: '' };

  change({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  submit() {
    this.setState({ busy: true });
    const { description } = this.state;
    
    ApiKeyResource.create({ description })
      .then(() => {
        this.setState({ busy: false });
        closeModal();
      })
      .catch(error => {
        this.setState({ 
          busy:  false,
          error: error.message || 'Failed to create API key'
        });
      });
  }

  render() {
    const { busy, error, description } = this.state;
    const change                       = this.change.bind(this);
    const buttonText                   = busy ? 'Creating API Key...' : 'Create API Key';

    return (
      <form
        autoComplete="off"
        className={classList('v-modal-form v-width-40 v-center-column v-center-left')}
      >
        <Textbox 
          name="description" 
          label="Description (Optional)" 
          placeholder="Main Warehouse, etc"
          disabled={busy} 
          onChange={change} 
          value={description} 
        />
        
        <div className="v-error-message">{error}</div>
        <br />
        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Cancel" white onClick={closeModal} />
          <Button disabled={busy} label={buttonText} onClick={this.submit.bind(this)} />
        </div>
      </form>
    );
  }
}
