import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { classList } from '../../helpers/classList';
import { showBusyMessage, showErrorMessage, showMessage, showModal } from '../../helpers/modal';
import { downloadURL, printURL } from '../../helpers/misc';
import {
  IconRefund,
  IconClose,
  IconExportDoc,
  IconLabels,
  IconAdd,
  IconPrint,
  IconDownload,
  IconSettings,
} from '../../helpers/icons';
import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import { Grid, Grid1 } from '../../components/Grids';
import { Textbox } from '../../components/Textbox';
import { Pagination } from '../../components/Pagination';
import { TableFiltersComponent } from '../../components/TableFiltersComponent';
import { tableConfig } from './config';
import { ShipmentResource } from '../../resources';
import { ShipmentModal } from './ShipmentModal';
import { SettingsModal } from './SettingsModal';
import { Title } from '../../components/Title';

export class _Shipments extends React.Component {
  state = { selectedShipmentIds: [], ...ShipmentResource.filters };

  resource = ShipmentResource

  propsWillUpdate = ['shipments']

  setPage = (page) => {
    if (page !== this.state.page) {
      this.setState({ page });
      this.resource.setFilter('page', page);
      this.resource.list();
    }
  }

  compile(ids, e, callback) {
    if (!window.confirm('This will compile the selected labels and mark them as downloaded, proceed?')) return;

    ShipmentResource.compile({ ids: ids || this.state.selectedShipmentIds }).then(({ success, message, report }) => {
      setTimeout(() => (success ? callback(report.url, report.format) : showErrorMessage(message)));
    });
  }

  download(ids, e) {
    e?.stopPropagation();
    this.compile(ids, e, (url) => downloadURL(url));
  }

  print(ids, e) {
    e?.stopPropagation();
    if (this.props.userSettings.label_format.value === 'zpl')
      return showErrorMessage(
        `We don't print ZPL files right now. If you want to compile and print labels as PDF, please go to Shipments Settings and uncheck 'Use ZPL format'.`
      );

    this.compile(ids, e, printURL);
  }

  showShipment(data) {
    showModal({
      title:     `${data.public_id} — ${data.date}`,
      component: ShipmentModal,
      payload:   { data, refund: this.refund.bind(this) },
    });
  }

  showSettings() {
    showModal({
      title:     `Configure Label Defaults`,
      component: SettingsModal,
    });
  }

  async refund(ids, e, force) {
    e?.stopPropagation();

    const shipments = this.props[this.propsWillUpdate[0]].shipments.filter((x) => ids.includes(x.id));
    if (!force && shipments.find((x) => !x.refundable)) {
      showErrorMessage('Some of the shipments are not refundable. Please remove them before refunding the rest!');
      return;
    }
    const q = shipments.find((x) => x.shop_order_id)
      ? 'This will request refund for the selected label(s). For each label, unless refund is immediately declined by the carrier, this will cancel the fulfillment on the related order in your shop. Proceed?'
      : 'This will request refund for the selected label(s), proceed?'
    if (!window.confirm(q)) return;

    const { success, report } = await ShipmentResource.refund(ids);
    if (success) {
      setTimeout(() => this.resource.list(false), 150);
      const { message, refund_statuses } = report;
      showMessage(
        <div>
          {message}
          <br />
          <table cellSpacing={0} cellPadding={0} className="v-refund-table">
            <thead>
              <tr>
                <th>Tracking Code</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(refund_statuses).map(({ tracking_code, status }) => (
                <tr key={tracking_code}>
                  <td>{tracking_code}</td>
                  <td>{status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>,
        null,
        true
      );
    } else {
      const { message, errors } = report;
      showErrorMessage(
        <div>
          {message}
          <div>{Object.values(errors).join(<br />)}</div>
        </div>
      );
    }
  }

  exportCSV() {
    this.resource.exportCSV(this.state.reportsEmail).then(({ success, message }) => {
      if (success) {
        showMessage(<div className="v-body-content">{message}</div>, null, true);
      } else {
        showErrorMessage(message);
      }
    });
  }

  emailChanged(event) {
    this.setState({ reportsEmail: event.target.value });
  }

  submit(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.exportCSV();
    }
  }

  onCheck(shipmentId) {
    const { selectedShipmentIds } = this.state;
    if (selectedShipmentIds.includes(shipmentId)) {
      selectedShipmentIds.splice(selectedShipmentIds.indexOf(shipmentId), 1);
    } else {
      selectedShipmentIds.push(shipmentId);
    }
    this.setState({ selectedShipmentIds: [...selectedShipmentIds] });
  }

  onCheckAll() {
    if (this.state.selectedShipmentIds.length) {
      this.setState({ selectedShipmentIds: [] });
    } else {
      this.setState({
        selectedShipmentIds: this.props.shipments.shipments.map((s) => s.id),
      });
    }
  }

  render() {
    const { page, per, q, selectedShipmentIds } = this.state;

    const { user, shipments } = this.props;
    const hasNoSelection      = selectedShipmentIds.length === 0;

    if (!user.person || user.person.permissions.shipment_reporting) {
      return (
        <div className={classList('v-awaiting-shipments', 'v-page')}>
          {user.person && <Title title="Shipments" />}
          {!user.person && (
            <Title title="Shipments">
              <Link to="/shipment/new" className="v-title-button">
                <Button icon={IconAdd}>New Shipment</Button>
              </Link>
              <Link to="/shipments/buy-labels" className="v-title-button" hidden>
                <Button icon={IconLabels}>Buy Labels</Button>
              </Link>
            </Title>
          )}

          <TableFiltersComponent
            resource={this.resource}
            display="filters dates search per"
            onChange={(p) => this.setState({ ...p })}
            filters={[
              { name: 'downloaded', options: shipments.options.downloaded, width: '15rem' },
              { name: 'source', options: shipments.options.sources, width: '15rem' }
            ]}
            presets={shipments.options.presets}
            searchPlaceholder="Search by shipment ID, tracking code, or reference"
          />

          <Grid className="v-center-left"
            style={{ margin: '2rem 0px', flexWrap: 'wrap', gap: '1rem' }}>
            <Grid1 className="v-center-right">
              <Button className="v-padding-left-1" onClick={this.showSettings} icon={IconSettings} small>
                Settings
              </Button>
              <Button
                disabled={hasNoSelection}
                className="v-padding-left-1"
                onClick={this.download.bind(this, selectedShipmentIds)}
                icon={IconDownload}
                white
                small
              >
                Download
              </Button>
              <Button
                disabled={hasNoSelection}
                className="v-padding-left-1"
                onClick={this.print.bind(this, selectedShipmentIds)}
                icon={IconPrint}
                white
                small
              >
                Print
              </Button>
              {!user.person && (
                <Button
                  disabled={hasNoSelection}
                  className="v-padding-left-1"
                  onClick={this.refund.bind(this, selectedShipmentIds)}
                  icon={IconRefund}
                  white
                  small
                >
                  Refund
                </Button>
              )}
              <form
                autoComplete="off"
                style={{ paddingLeft: '1rem', marginLeft: '1rem', borderLeft: '1px solid #aaa' }}
              >
                <Textbox
                  type="email"
                  placeholder={user.email}
                  style={{ border: '1px solid #aaa', borderRadius: '4px' }}
                  className="v-email-override"
                  onChange={this.emailChanged.bind(this)}
                  onKeyDown={this.submit.bind(this)}
                  value={this.state.reportsEmail}
                />
              </form>
              <Button
                disabled={!shipments.total_count}
                className="v-padding-left-1"
                onClick={this.exportCSV.bind(this)}
                icon={IconExportDoc}
                white
                small
              >
                Export CSV
              </Button>
            </Grid1>
          </Grid>

          <Table
            noScroll
            data={shipments.shipments}
            onClickRow={this.showShipment.bind(this)}
            context={this}
            config={tableConfig.call(this)}
            tableClass="v-table-payment-log"
          />
          <Pagination
            total_count={shipments.total_count}
            currentPage={page}
            perPage={per}
            onChange={this.setPage.bind(this)}
          />
        </div>
      );
    }

    return (
      <div className="v-page">
        <Title title="Access denied" />
      </div>
    );
  }
}

export default connect(({ shipments, user, userSettings }) => ({
  shipments,
  user,
  userSettings,
}))(_Shipments);
