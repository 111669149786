import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { startCase } from 'lodash';
import { Select } from '../../components/Select';
import { Button } from '../../components/Button';
import { Title } from '../../components/Title';
import { ReportsResource } from '../../resources';

import { showErrorMessage } from '../../helpers/modal';
import { showCalendarModal, createDate, getFormattedDate, getPastDays } from '../../components/Calendar';

class GenerateReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom:        getFormattedDate(getPastDays(7)),
      dateTo:          getFormattedDate(getPastDays(-1)),
      transactionType: 'all',
    };
  }

  change = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  generate = () => {
    const { dateFrom, dateTo, reportType, transactionType } = this.state;
    const { history }                                       = this.props;

    ReportsResource.create(
      {
        start_date:  new Date(dateFrom).toLocaleDateString('en-CA'),
        end_date:    new Date(dateTo).toLocaleDateString('en-CA'),
        report_type: reportType,
        params:      { kind: transactionType },
      },
      'Generating Report...',
      {
        onSuccess: () => {
          history.push('/reports');
        },
        onError: (r) => {
          showErrorMessage(r.message);
        },
      }
    ).then((r) => {
      if (!r.success) {
        showErrorMessage(r.message);
      }
    });
  };

  onChangeDates = ([from, to]) => {
    this.setState({
      dateFrom: getFormattedDate(from),
      dateTo:   getFormattedDate(to),
    });
  };

  render() {
    const { dateFrom, dateTo, reportType, transactionType } = this.state;
    const formattedDateInterval                             = `${dateFrom} - ${dateTo}`;
    const { options }                                       = this.props.reports;

    return (
      <div className="v-page">
        <Title title="Generate Report" subtitle="One place to generate all report types." />
        <h3>Report Type</h3>
        <Select
          name="reportType"
          onChange={this.change}
          value={reportType}
          items={options.report_types.slice(1).map(([label, value]) => ({ label, value }))}
          className="v-padding-right-3 v-select-small-no-border v-width-225"
        />
        <h3>Date Range</h3>
        <Button
          onClick={showCalendarModal([createDate(dateFrom), createDate(dateTo)], this.onChangeDates)}
          white
          label={formattedDateInterval}
        />
        {reportType === 'dashboard_balances' && (
          <>
            <h3>Transaction Type</h3>
            <Select
              items={options['params.kinds'].map(([label, value]) => ({ label, value }))}
              className="v-padding-right-3 v-select-small-no-border v-width-225"
              onChange={this.change}
              value={transactionType}
              name="transactionType"
            />
          </>
        )}
        <div>
          <Button className="v-margin-top-2" onClick={this.generate} disabled={!reportType}>
            Generate
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(({ reports }) => ({ reports }))(GenerateReport));
