import React from 'react';
import { connect } from 'react-redux';
import { Title, Table, TableFiltersComponent, Button } from '../../components';
import { ReportSchedulesResource } from '../../resources';
import { showModal } from '../../helpers/modal';
import { ReportScheduleModal } from './ReportScheduleModal';
import tableConfig from './tableConfig';

class ReportSchedules extends React.Component {
  createSchedule() {
    showModal({
      title:     'New Scheduled Report',
      component: ReportScheduleModal
    });
  };

  delete(id) {
    ReportSchedulesResource.delete(id);
  }

  test(id) {
    ReportSchedulesResource.test(id);
  }

  render() {
    const { records, options } = this.props.reportSchedules;

    return (
      <div className="v-page">
        <Title title="Scheduled Reports">
          <div className="v-title-button">
            <Button onClick={this.createSchedule}>Add Schedule</Button>
          </div>
        </Title>

        <TableFiltersComponent
          resource={ReportSchedulesResource}
          filters={[
            { name: 'report_type', label: 'Type', options: options.report_types },
            { name: 'period', label: 'Period', options: options.periods },
          ]}
          onChange={(p) => this.setState({ ...p })}
        />

        <Table noScroll data={records} context={this} config={tableConfig.call(this)} trClass={this.trClass} />
      </div>
    );
  }
}

export default connect(({ reportSchedules }) => ({ reportSchedules }))(ReportSchedules);
