import React from 'react';
import { Button } from '../../components/Button';
import { classList } from '../../helpers/classList';
import { PaymentMethodResource } from '../../resources';
import { closeModal, showModal } from '../../helpers/modal';
import { IconBank } from '../../helpers/icons';
import { RemoveModal } from './RemoveModal';
import { VerifyModal } from './VerifyModal';

export class BankAccount extends React.Component {
  performDelete() {
    const {
      account: { id },
      setProcessing,
    } = this.props;
    setProcessing && setProcessing();
    PaymentMethodResource.delete(id, 'Removing payment method...').then(() => {
      setProcessing && setProcessing();
      closeModal();
    });
  }

  performVerify(amounts) {
    const {
      account: { id },
    } = this.props;
    PaymentMethodResource.verify(id, amounts).then(() => window.location.reload());
  }

  deleteCard() {
    const { id, name, last4 } = this.props.account;
    showModal({
      title:   `Delete`,
      payload: {
        id,
        title:         name,
        message:       `Remove bank account ending in ${last4}?`,
        performDelete: this.performDelete.bind(this),
      },
      component: RemoveModal,
    });
  }

  verify() {
    const { id, name, last4 } = this.props.account;
    showModal({
      title:   `Verify your account`,
      payload: {
        id,
        title:         name,
        message:       `Verify account ending in ${last4}?`,
        performVerify: this.performVerify.bind(this),
      },
      component: VerifyModal,
    });
  }

  render() {
    const {
      processing,
      account: { issuer, name, last4, primary, issuer_code, verified, type, plaid_id },
    } = this.props;
    // console.log(this.props)
    return (
      <div className={classList('v-credit-card-container v-center-left')}>
        {issuer_code ? (
          <img src={`/assets/banks/${issuer_code}.png`} className="v-credit-card-icon" alt={issuer_code || '?'} />
        ) : plaid_id ? (
          <img src={`https://s3.us-west-2.amazonaws.com/cdn.vanlo.com/logos/${issuer}.png`} className="v-credit-card-icon" alt={issuer} />
        ) : (
          <IconBank className="v-credit-card-icon" />
        )}
        <div className="v-credit-card-info v-left">
          <div className="v-bank-account-issuer">{issuer}</div>
          <div className="v-bank-account-name">{name}</div>
          <div className="v-bank-account-last4">
            <span>xxxx-xxxx-xxxx-{last4}</span>
            <div className="v-flex v-center" style={{ gap: '0.5rem' }}>
              {primary && <span className="v-credit-card-primary">Primary</span>}
              {(verified || type === 'PayoutBankAccount') && <span className="v-credit-card-primary">Verified</span>}
            </div>
          </div>
          <Button disabled={processing} white onClick={() => this.deleteCard()} small>
            Delete
          </Button>
          {!verified && type !== 'PayoutBankAccount' && (
            <Button disabled={processing} white onClick={() => this.verify()} small>
              Verify
            </Button>
          )}
        </div>
      </div>
    );
  }
}
