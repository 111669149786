import React from 'react';
import { map, filter } from 'lodash';
import { Multiselect } from 'react-widgets';

import { Select } from './Select';
import { Grid, Grid1 } from './Grids';
import { Button } from './Button';
import { Textbox } from './Textbox';
import { showCalendarModal, createDate, getFormattedDate } from './Calendar';
import { IconClose } from '../helpers/icons';
import { writeStorageData } from '../helpers/localStorage';

const numberOfRows = [10, 25, 50, 100, 200].map((value) => ({
  label: <span>{value}</span>,
  value,
}));

export class TableFiltersComponent extends React.Component {
  state = { filters: { ...this.props.resource.filters } };

  resetDefaults() {
    const { resource } = this.props;
    resource.resetFilters();
    this.setState({ filters: { ...resource.filters } });
    resource.list();
  }

  clearSearch(resource) {
    this.changeFilter(true, { target: { name: 'q', value: null } });
  }

  changeFilter(reload, params) {
    const { resource, type, onChange } = this.props;

    if (Array.isArray(params)) {
      if (!params[0] || params[0].id) {
        resource.setFilter('preset_ids', map(params, 'id'));
      } else {
        resource.setFilter('date_from', params[0]);
        resource.setFilter('date_to', params[1]);
      }
    } else {
      const { target: { name, value } } = params;
      const field                       = name === 'type' ? type : name;
      resource.setFilter(field, value);
      resource.setFilter('page', 1);
      name === 'per' && writeStorageData('per', value);
      name === 'sort_by' && writeStorageData(`${this.props.resource.name}.sort_by`, value);
    }

    const newFilters = { ...this.state.filters, ...resource.filters, page: 1 };
    this.setState({ filters: newFilters });
    onChange(newFilters);

    reload && resource.list();
  }

  render() {
    const { filterTypesList, type, display, filters, presets, resource, searchPlaceholder } = this.props;

    const { date_from, date_to, per, q, preset_ids } = this.state.filters;

    const formattedDate = `${date_from} - ${date_to}`;
    const change        = this.changeFilter.bind(this, true);
    const onChangeDates = ([from, to]) => this.changeFilter(true, [getFormattedDate(from), getFormattedDate(to)]);

    return (
      <Grid style={{ margin: '2rem 0', flexWrap: 'wrap', gap: '1rem' }}>
        {display?.includes('search') && (
          <Grid1 className="v-center-left">
            <Textbox
              name="q"
              onKeyPress={(e) => e.key === 'Enter' && resource.list()}
              value={q}
              onChange={this.changeFilter.bind(this, false)}
              placeholder={searchPlaceholder}
              title={searchPlaceholder}
              className="v-search-box"
              style={{ minWidth: '24rem', maxWidth: '100%' }}
            />
            {q && <IconClose onClick={this.clearSearch.bind(this)} className="v-clear-search-box-icon v-clickable" />}
          </Grid1>
        )}

        <Grid1 className="v-center-right" style={{ gap: '0.5rem' }}>
          {filters?.map((filter, i) => (
            filter.options && <Select
              key={i}
              name={filter.name}
              labelLeft={filter.label}
              inline={filter.label === false}
              onChange={change}
              value={this.state.filters[filter.name]}
              items={(filter.options).map(([label, value]) => ({
                label,
                value,
              }))}
              className="v-select-small-no-border"
              style={{ width: filter.width || '22rem' }}
            />
          ))}
          {!filters && (!display || display?.includes('types')) && (
            <Select
              name="type"
              onChange={change}
              value={this.state.filters[type]}
              items={(filterTypesList || []).map(([label, value]) => ({ label, value }))}
              className="v-padding-right-1 v-select-small-no-border v-width-225"
            />
          )}
          {(filters ? display?.includes('dates') : !display || display?.includes('dates')) && (
            <>
              <Button
                onClick={showCalendarModal([createDate(date_from), createDate(date_to)], onChangeDates.bind(this))}
                white
                label={formattedDate}
                style={{ minWidth: '18rem' }}
              />
              <Button onClick={this.resetDefaults.bind(this)} small white label="Reset Dates" style={{ minWidth: '8rem' }} />
            </>
          )}
        </Grid1>

        {presets && (
          <Grid1 className="v-center-left">
            {presets?.length !== 0 && (
              <Multiselect
                data={presets}
                dataKey="id"
                textField="name"
                filter="contains"
                value={preset_ids || map(filter(presets, 'default'), 'id')}
                onChange={(values) => this.changeFilter(true, values)}
                style={{ minWidth: '42rem', maxWidth: '100%' }}
                placeholder="Search Presets"
                title="You can find and customize Search Presets in the Settings menu"
              />
            )}
            {presets?.length === 0 && <a href='/search-presets'>Search Presets</a>}
          </Grid1>
        )}

        {(filters ? display?.includes('per') : !display || display?.includes('per')) && (
          <Grid1 className="v-center-right" style={{ maxWidth: '8rem' }}>
            <Select
              name="per"
              labelLeft="Show by"
              onChange={change}
              value={per}
              items={numberOfRows}
              className="v-select-small-no-border"
              style={{ width: '7rem' }}
            />
          </Grid1>
        )}
      </Grid>
    );
  }
}
