import React from 'react';
import { Checkbox } from '../../components/Checkbox';
import { IconPrint, IconDownload, IconRefund } from '../../helpers/icons';
import { classList } from '../../helpers/classList';
import { getVanloURL } from '../../resources/query';
import { printURL } from '../../helpers/misc';

export function tableConfig() {
  return [
    {
      title:     <Checkbox checked={this.state.selectedShipmentIds.length > 0} onChange={this.onCheckAll.bind(this)} />,
      className: 'v-table-checkbox',
      cell:      (...[, , , shipment]) => (
        <Checkbox
          checked={this.state.selectedShipmentIds.includes(shipment.id)}
          onChange={this.onCheck.bind(this, shipment.id)}
        />
      ),
    },
    {
      title:     'Date (UTC)',
      field:     'date',
      className: 'date',
    },
    {
      title: 'ID',
      field: 'public_id',
    },
    {
      title:     'To',
      field:     'to_address',
      className: 'to',
      cell:      (to_address, r, c, shipment) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          {shipment.to_name}
          <span style={{ color: '#777777', fontSize: '1.2rem', lineHeight: 1.25 }}>{to_address}</span>
        </div>
      ),
      flat:  (to_address, r, c, shipment) => `${shipment.to_name}\n${to_address}`,
      style: { minWidth: '35rem' },
    },
    {
      title: 'Tracking status',
      field: 'tracking_code',
      style: { minWidth: '21rem' },
      cell:  (data, row, col, all) => (
        <div
          style={{
            display:       'flex',
            flexDirection: 'column',
          }}
        >
          <span style={{ color: '#777777', fontSize: '1.2rem', lineHeight: 1.25 }}>
            {all.status === 'Unknown' ? 'Ready To Ship' : all.status}
          </span>
          {data}
        </div>
      ),
      flat: (data, row, col, all) => all.tracking_code,
    },
    {
      title: 'Service / Price',
      field: 'service',
      style: { width: '20rem', flex: 'unset' },
      cell:  (data, row, col, all) => (
        <div
          style={{
            display:       'flex',
            flexDirection: 'column',
          }}
        >
          <span style={{ color: '#777777', fontSize: '1.2rem', lineHeight: 1.25 }}>{all.service}</span>
          <span>{all.price}</span>
        </div>
      ),
      flat: (data, row, col, all) => `${all.service} / ${all.price}`,
    },
    {
      title: 'Actions',
      style: { minWidth: '8rem', flex: 'unset' },
      cell:  (data, row, col, { label_url, refundable, label_format, id }) => {
        const printable = label_format && label_format !== 'zpl';
        return (
          <div>
            <a href={label_url} target="_blank" rel="noreferrer" title="Download the label">
              <IconDownload
                onClick={(e) => e.stopPropagation()}
                className={classList('v-icon-button', label_format ? '' : 'disabled')}
              />
            </a>
            <span title="Print the label">
              <IconPrint
                onClick={(e) => [e.stopPropagation(), printable && printURL(label_url, label_format)]}
                style={{ opacity: printable ? 1 : 0.4 }}
                className={classList('v-icon-button', printable ? '' : 'disabled')}
              />
            </span>
            <span title="Refund the label">
              <IconRefund
                onClick={(e) => [e.stopPropagation(), refundable && this.refund([id], e)]}
                style={{ opacity: refundable ? 1 : 0.4 }}
                className={classList('v-icon-button', refundable ? '' : 'disabled')}
              />
            </span>
          </div>
        );
      },
    },
  ];
}
