import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { Title } from '../../components/Title';
import { Button } from '../../components/Button';
import { showModal } from '../../helpers/modal';
import { EditWarehouseContainer } from './EditWarehouseContainer';
import { Table } from '../../components/Table';
import { DeleteWarehouseContainer } from './DeleteWarehouseContainer';
import { WarehouseAddressesResource } from '../../resources';
import { Pagination } from '../../components/Pagination';
import { TableFiltersComponent } from '../../components/TableFiltersComponent';

const addNewWarehouse = () => {
  showModal({
    title:     'Add warehouse',
    component: EditWarehouseContainer,
  });
};

const onEditWarehouse = (warehouse) => () => {
  showModal({
    title:     'Edit warehouse',
    component: EditWarehouseContainer,
    payload:   { warehouse },
  });
};

const onDeleteWarehouse = (warehouse) => () => {
  showModal({
    title:     'Delete warehouse',
    component: DeleteWarehouseContainer,
    payload:   { warehouse },
  });
};

const tableConfig = [
  {
    title: 'Name',
    field: 'name',
    cell:  (data) => data,
  },
  {
    title: 'Address',
    field: 'fullAddress',
    cell:  (data) => data,
  },
  {
    title: 'Last used',
    field: 'last_used',
    cell:  (data) => data,
  },
  {
    title: 'Actions',
    field: '',
    cell:  (data, row, col, warehouse) => (
      <div>
        <Button label="Edit" small white onClick={onEditWarehouse(warehouse)} />
        <Button className="v-margin-left-1rem" label="Delete" small white onClick={onDeleteWarehouse(warehouse)} />
      </div>
    ),
  },
];

export class _Locations extends React.Component {
  state = { ...WarehouseAddressesResource.filters };

  resource = WarehouseAddressesResource

  propsWillUpdate = ['warehouseAddresses']

  setPage = (page) => {
    if (page !== this.state.page) {
      this.setState({ page });
      this.resource.setFilter('page', page);
      this.resource.list();
    }
  }

  render() {
    const { page, per } = this.state;
    return (
      <div className={classList('v-awaiting-shipments', 'v-page')}>
        <Title title="Locations" subtitle="Add your warehouse locations." />
        <Button label="ADD NEW WAREHOUSE" onClick={addNewWarehouse} style={{ top: '120px', position: 'absolute' }} />

        <TableFiltersComponent
          resource={this.resource}
          display="per"
          onChange={(p) => this.setState({ ...p })}
        />
        <Table data={this.props.warehouseAddresses.warehouse_addresses} config={tableConfig} />
        <Pagination
          total_count={this.props.warehouseAddresses.total_count}
          currentPage={page}
          perPage={per}
          onChange={this.setPage.bind(this)}
        />
      </div>
    );
  }
}

export default connect(({ warehouseAddresses }) => ({
  warehouseAddresses,
}))(_Locations);