import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { Button } from '../../components/Button';
import { Hr } from '../../components/Hr';
import { Select } from '../../components/Select';
import { getNumber, formatCurrency } from '../../helpers/data';
import { closeModal, showTextMessage, showErrorMessage } from '../../helpers/modal';
import { BalanceResource } from '../../resources';

class AddFundModalComponent extends React.Component {
  state = {};

  change({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  update() {
    const { payment_method, amount } = this.state;
    const data                       = {
      amount:       getNumber(amount || this.props.topup.amount.value),
      topup_method: payment_method,
    };
    BalanceResource.update(data, 'Updating Balance...').then((r) => {
      if (r.success) {
        showTextMessage(
          <>
            Balance updated successfully to <b>{formatCurrency(r.balance.available_value)}</b> !
          </>
        );
      } else {
        showErrorMessage(r.message);
      }
    });
  }

  render() {
    const { payment_method, amount } = this.state;
    const { topup }                  = this.props;
    return (
      <div className={classList('v-modal-form v-center-column v-center-left')} style={{ padding: '1rem 0 0 .5rem' }}>
        <Select
          labelLeft="Payment:"
          value={payment_method || topup.payment_method.value}
          styleLabelLeft={{ width: '7rem' }}
          name="payment_method"
          className="v-select-small-no-border v-width-25"
          classOpenOptions="v-fixed v-width-25"
          items={topup.payment_method.options.map((m) => ({
            label: m[0],
            value: m[1],
          }))}
          onChange={this.change.bind(this)}
        />
        <br />
        <Select
          labelLeft="Amount:"
          value={amount || topup.amount.value}
          styleLabelLeft={{ width: '7rem' }}
          name="amount"
          className="v-select-small-no-border v-width-25"
          classOpenOptions="v-fixed v-width-25"
          items={topup.amount.options.map((m) => ({
            label: formatCurrency(m, true),
            value: m,
          }))}
          onChange={this.change.bind(this)}
        />
        <Hr className="v-hr-2" />
        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button
            disabled={!payment_method && !topup.payment_method}
            label="Charge"
            onClick={this.update.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export const AddFundModal = connect(({ userSettings, balance }) => ({
  userSettings,
  topup: balance.topup,
}))(AddFundModalComponent);
