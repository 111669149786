import React from 'react';
import { connect } from 'react-redux';
import { Select, Button, Textbox } from '../../components';
import { ReportSchedulesResource } from '../../resources';
import { showErrorMessage, closeModal } from '../../helpers/modal';

class _ReportScheduleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:           this.props.user.email,
      reportType:      '',
      period:          '',
      transactionType: '',
      busy:            false,
      error:           ''
    };
  }

  change = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  submit = () => {
    const { email, reportType, period, transactionType } = this.state;

    this.setState({ busy: true });

    ReportSchedulesResource.create(
      {
        email,
        report_type: reportType,
        period,
        params:      { kind: transactionType },
      },
      'Creating...',
      {
        onSuccess: () => {
          this.setState({ busy: false });
          closeModal();
          ReportSchedulesResource.list();
        },
        onError: (r) => {
          this.setState({ busy: false, error: r.message });
          showErrorMessage(r.message);
        },
      }
    );
  };

  render() {
    const { email, reportType, period, transactionType, busy, error } = this.state;
    const { options }                                                 = this.props.reportSchedules;
    const buttonText                                                  = busy ? 'Creating...' : 'Create Schedule';

    return (
      <form autoComplete="off" className="v-modal-form v-width-40 v-center-column v-center-left">
        <Textbox
          label="Recipient Email"
          name="email"
          placeholder="hi@example.com"
          onChange={this.change}
          value={email}
        />

        <Select
          name="reportType"
          label="Report Type"
          onChange={this.change}
          value={reportType}
          items={options.report_types.slice(1).map(([label, value]) => ({ label, value }))}
        />

        <Select
          name="period"
          label="Period"
          onChange={this.change}
          value={period}
          items={options.periods.slice(1).map(([label, value]) => ({ label, value }))}
        />

        {reportType === 'dashboard_balances' && (
          <Select
            label="Transaction Type"
            items={options['params.kinds'].slice(1).map(([label, value]) => ({ label, value }))}
            onChange={this.change}
            value={transactionType}
            name="transactionType"
          />
        )}

        <div className="v-error-message">{error}</div>
        <br />

        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Cancel" white onClick={closeModal} />
          <Button disabled={busy || !reportType || !period || !email} label={buttonText} onClick={this.submit} />
        </div>
      </form>
    );
  }
}

export const ReportScheduleModal = connect(({ reportSchedules, user }) => ({ reportSchedules, user }))(_ReportScheduleModal); 