import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';
import { classList } from '../../helpers/classList';
import { IconArrow } from '../../helpers/icons';
import { sidebarMenuItems, detectOpen } from './sidebarMenuItems';
import { store } from '../../store';

class SidebarItemsComponent extends React.Component {
  state = {};

  componentDidUpdate(prevProps, prevState) {
    !isEqual(prevProps?.user, this.props?.user) && this.resetState();
  }

  openGroup(num) {
    const { open } = this.state;
    this.setState({ open: open === num ? -1 : num });
  }

  resetState = () => {
    this.setState({ open: detectOpen(this.props.activeRoute, this.props.user, this.props.server) });
  };

  render() {
    const { open }         = this.state;
    const { user, server } = this.props;
    return (
      <div className="v-sidebar-menu v-dark-scroller">
        {sidebarMenuItems
          .filter((item) => !item.condition || item.condition(user, server))
          .map((x, index) => {
            const Row = x.list ? SidebarItemMultiple : SidebarItemSingle;
            return (
              <Row
                key={index}
                user={user}
                server={server}
                toggleOpen={this.openGroup.bind(this, index)}
                isOpen={index === open}
                activeRoute={this.props.activeRoute}
                className={x.list ? '' : 'v-separator'}
                {...x}
              />
            );
          })}
      </div>
    );
  }
}

const SidebarItemSingle = ({ activeRoute, label, onClick, route, icon: Icon, className, component: Component }) => {
  const isSelected = route && route === activeRoute;
  if (Component)
    return (
      <div className={classList('v-sidebar-item', className, isSelected ? 'v-selected' : '')}>
        <Component />
      </div>
    );
  return (
    <Link to={route || '#'} className={classList('v-sidebar-item', className, isSelected ? 'v-selected' : '')}>
      <span className="v-sidebar-label-title" onClick={onClick}>
        {Icon && <Icon className="v-item-icon" />}
        {label}
      </span>
    </Link>
  );
};

const SidebarItemMultiple = ({
  label,
  icon: Icon,
  list,
  isOpen,
  toggleOpen,
  setSelection,
  activeRoute,
  user,
  server,
}) => {
  const listToDisplay = list.filter((item) => !item.condition || item.condition(user, server));
  const height        = isOpen ? `calc(${listToDisplay.length} * var(--sidebar-items-height))` : 0;

  return (
    <div className="v-sidebar-items">
      <div
        className={classList('v-sidebar-items-label', isOpen && 'v-open', isOpen && 'v-selected')}
        onClick={toggleOpen}
      >
        <span className="v-sidebar-label-title">
          {Icon && <Icon className="v-item-icon" />}
          {label}
        </span>
        <IconArrow className="v-icon-10 v-arrow-icon" />
      </div>
      <div className="v-sidebar-list" style={{ height }}>
        {listToDisplay.map((y, key) => {
          const isSelected = activeRoute === y.route;
          return <SidebarItemSingle key={key} {...{ ...y, setSelection, isSelected, activeRoute }} />;
        })}
      </div>
    </div>
  );
};

export const SidebarItems = connect(({ user, server }) => ({ user, server }))(SidebarItemsComponent);
