import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { PasswordBox } from '../../components/PasswordBox';
import { Button } from '../../components/Button';
import { copyText } from '../../helpers/variables';
import { showBusyMessage, showModal } from '../../helpers/modal';
import { ApiKeyResource } from '../../resources';
import { setSession } from '../../helpers/sessions';
import { Table } from '../../components/Table';
import { Title } from '../../components/Title';
import { ApiKeyModal } from './ApiKeyModal';

function tableConfig(apiKeys) {
  return [
    {
      title: 'Date (UTC)',
      field: 'created_at',
      style: { maxWidth: '18rem' },
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title:     'Key',
      field:     'token',
      className: 'v-no-pad',
      style:     { maxWidth: '26rem' },
      cell:      (data) => <PasswordBox value={data} type="password" className="v-api-key-value" />,
    },
    {
      title: 'Actions',
      field: 'default',
      cell:  (data, row, col, all) => (
        <div className="v-center">
          <Button label="Copy" small white onClick={() => this.copyText(all.token)} />
          {apiKeys.length === 1 ? (
            <Button className="v-margin-left-1rem" label="Delete" disabled small white onClick={null} />
          ) : (
            <Button className="v-margin-left-1rem" label="Delete" small white onClick={() => this.deleteKey(all.id)} />
          )}
        </div>
      ),
    },
  ];
}

class ApiKeys extends React.Component {
  state = {};

  copyText(key) {
    copyText(key);
    const pr = new Promise((res) => setTimeout(res, 750));
    showBusyMessage('Api Key copied into your clipboard!', pr);
  }

  createKey() {
    showModal({
      title:     'Add API Key',
      component: ApiKeyModal
    });
  }

  deleteKey(id) {
    setSession(this.props.apiKeys.find((key) => key.id !== id).token);
    ApiKeyResource.delete(id);
  }

  setDefaultKey(id) {
    ApiKeyResource.setDefault(id).then(() => {
      setSession(this.props.apiKeys.find((key) => key.id === id).token);
    });
  }

  render() {
    const { apiKeys } = this.props;
    return (
      <div className={classList('v-awaiting-shipments', 'v-page')}>
        <Title
          title="API Key"
          subtitle={
            <>
              Check the &nbsp;
              <a
                className="v-clickable"
                style={{ color: 'rgb(3, 169, 244)' }}
                href="https://api-docs.vanlo.com/"
                target="_blank"
                rel="noreferrer"
              >
                API Documentation
              </a>
              &nbsp; for more information!
            </>
          }
        />
        <Button label="Create New API Key" className="v-margin-bottom-2" onClick={this.createKey.bind(this)} />
        <Table noScroll data={apiKeys} config={tableConfig.call(this, apiKeys)} />
      </div>
    );
  }
}

export default connect(({ apiKeys }) => ({ apiKeys }))(ApiKeys);
